/*
* {
  border: 1px solid red !important;
}
*/

/* for google maps */
.autocomplete_container {
  width: 100%;
}
.map-container {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.content {
  flex-grow: 1;
  position: relative;
  top: 0;
  min-height: calc(100vh - 150px - 10vh);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  justify-content: center;

  z-index: 1; /* puts it behind zindex 2 */

  /* sets the space between the content and the footer/navbar */
  margin-top: 5%;
  margin-bottom: 5%;

  width: 100%; /* Make sure the content takes full width within the container */
}

.content-create-event {
  flex-grow: 1;
  position: relative;
  top: 0;
  min-height: calc(100vh - 150px - 10vh);
  display: flex;
  flex-direction: column;
  background-color: #fff;

  z-index: 1; /* puts it behind zindex 2 */

  /* sets the space between the content and the footer/navbar */
  margin-top: 5%;
  margin-bottom: 5%;
}

nav {
  flex-shrink: 0;
  position: sticky;
  top: 0;
  width: 100%;
  display: flex;
  background-color: #0f0f0f;
  border-bottom: 1px solid #eee;

  z-index: 2; /* puts it infront */
  height: 75px;
}

footer {
  flex-shrink: 0;
  position: relative;
  align-self: center;
  bottom: 0;
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;

  z-index: 1; /* puts it behind zindex 2 */
  margin-top: auto;
  height: 75px;
}

.login-box {
    /* center the image */
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
  
    /* take full size of its container */
    height: 100vh;
    width: 100vw;
}

.full-background {
  /* center the image */
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;

  /* take full size of its container */
  height: 100vh;
  width: 100vw;

  /* background image */
  /*
  background: url(./assets/sheets-bg.jpg)
    center center / cover no-repeat;
  */
}

.fallback {
  /* center the image */
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;

  /* take full size of its container */
  height: 100vh;
  width: 100vw;

  background-color: #0f0f0f;
}

.loading {
  background: #c1c1c1;
  min-height: 30px;
  position: relative;
  overflow: hidden;
}

.loading-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.sign-in {
  color: #282c34;
  background: white;
  max-width: 400px;
  margin: 0 auto;
}